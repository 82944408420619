<template>
  <div>

    <form-wizard
      ref="formWizard"
      color="rgba(var(--vs-primary), 1)"
      :title="null"
      :subtitle="null"
      :hideButtons="true"
      step-size="xs"
      @on-change="tabChanged"
      :enableAllNav="true">

      <tab-content :title="$t('conteudo')" class="mb-5" icon="feather icon-file-text">

        <vx-card>

          <div class="grid grid-cols-12">
            <div class="col-span-8">
              <div class="vx-row mb-2">
                <div class="vx-col sm:w-1/1 w-full mb-2">

                  <label class="col-span-3 p- font-medium text-grey"> {{ $t('tipo') }} </label>
                  <div class="flex gap-2 flex-grow-0 mt-2">
                    <div @click="type='M'" :class="['flex ml-2 cursor-pointer', type === 'M' ? 'edu-switch-selected' : 'edu-switch']">
                      <font-awesome-icon icon="book-reader" class="h-6 w-6 text-success"/>
                      <span class="text-success ml-2">{{ $t('content_types.M') }}</span>
                    </div>
                    <div @click="type='A'" :class="['flex cursor-pointer', type === 'A' ? 'edu-switch-selected' : 'edu-switch']">
                      <font-awesome-icon icon="file-alt" class="h-6 w-6 text-warning"/>
                      <span class="text-warning ml-2">{{ $t('content_types.A') }}</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col sm:w-1/1 w-full mb-2">
                  <vs-input
                    name="title"
                    id="model_title"
                    class="w-full required"
                    :label="$t('classes_title')"
                    v-model="title"
                    v-validate:name="'required|max:191'"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('title')">{{
                    errors.first('title')
                  }}</span>
                </div>
              </div>

              <div class="vx-row mt-2">
                <div class="vx-col sm:w-1/1 w-full mb-2">
                  <label id="description">{{ $t('instrucoes') }}</label>
                  <ComplexEditor
                    :questionnaire_id.sync="contentId"
                    v-bind:editor_data.sync="editorData"
                    :placeholder="$t('digite-aqui')"
                  ></ComplexEditor>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('description')"
                    >{{ errors.first('description') }}</span
                  >
                </div>
              </div>

              <div class="flex mt-2">
                <div>
                  <feather-icon
                    icon="UploadIcon"
                    svgClasses="stroke-current w-6 h-6"
                    class="cursor-pointer"
                    @click="showUploadFile"
                    v-tooltip.bottom-start="{
                      content: 'Enviar arquivo do seu computador.',
                      delay: { show: 1000, hide: 100 }
                    }"
                  />
                </div>
                <div>
                  <feather-icon
                    icon="LinkIcon"
                    svgClasses="stroke-current w-6 h-6"
                    class="cursor-pointer pl-4"
                    @click="showUploadLinkModal"
                    v-tooltip.bottom-start="{
                      content: 'Adicione um link da internet.',
                      delay: { show: 1000, hide: 100 }
                    }"
                  />
                </div>
                <div>
                  <EduGooglePicker :clientId="'122025529505-sl56h9ddcpst6gcb7hcu94m1vtbbg630.apps.googleusercontent.com'"
                    :developerKey="'AIzaSyASQbPfm2FycUmhBTUnctF9RxP1o3j7FDc'"
                    :scope="['https://www.googleapis.com/auth/drive.readonly']"
                    @change="change"
                    @loaded="loaded"
                    :multiselect="true"
                    :navHidden="false"
                    :authImmediate="false"
                    :upload="true"
                    :viewId="'DOCS'">

                      <font-awesome-icon
                        :icon="icons.faGoogleDrive"
                        class="cursor-pointer pl-4"
                        style="font-size: 35px !important; height: 20px;"
                        v-tooltip.bottom-start="{
                          content: 'Adicione um arquivo do Google Drive.',
                          delay: { show: 1000, hide: 100 }
                        }"
                      />
                  </EduGooglePicker>
                </div>
                <!-- <div>
                  <feather-icon
                    icon="YoutubeIcon"
                    svgClasses="stroke-current w-6 h-6"
                    class="cursor-pointer pl-4"
                    v-tooltip.bottom-start="{
                      content: 'Adicione um video do Youtube.',
                      delay: { show: 1000, hide: 100 }
                    }"
                  />
                </div> -->
              </div>

              <div v-if="id" class="mt-2 w-full">
                <content-media-list @deleteMedia="deleteMedia" :medias="medias"/>
              </div>
            </div>
            <div class="col-span-4 ml-2 " style="border-left: 1px solid rgba(0, 0, 0, 0.2);">
              <div class="flex flex-col gap-4 px-2 h-full">

                <!-- TURMAS -->
                <div>
                  <vs-select
                    class="w-full"
                    :placeholder="$t('selecione-turmas-para-compartilhar')"
                    multiple
                    autocomplete
                    label="Turmas:"
                    v-model="selectedClasses"
                    noData="Escolha uma ou mais turmas para compartilhar."
                  >
                    <vs-select-item
                      v-for="item in classesList"
                      :key="item"
                      :value="item.id"
                      :text="`${item.title}<br/><label class=\'text-sm\'>  ${studentsQuantity(item)} Aluno(s)</label>`"
                    />
                  </vs-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('selected_classes')"
                    >{{ errors.first('selected_classes') }}</span
                  >
                </div>

                <!-- TEMAS -->
                <div :key="selectThemeKey">
                  <div v-if="creatingTheme" class="w-full flex items-end gap-2">
                    <vs-input
                      ref="theme-input"
                      class="flex-grow"
                      :label="$t('titulo-do-tema')"
                      v-model="creatingThemeTitle"
                    />
                    <vs-button
                    color="danger"
                    class="flex-grow-0 flex-shrink-0"
                    type="border"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="creatingTheme = false; theme = null"
                  ></vs-button>
                  </div>
                  <vs-select
                    v-else
                    @input="checkThemeCreation"
                    class="w-full"
                    :disabled="!hasAnyClassSelected"
                    :placeholder="$t('selecione-o-tema-para-vincular')"
                    autocomplete
                    label="Tema:"
                    v-model="theme"
                    noData="Escolha uma ou mais tema para vincular ao conteúdo."
                  >
                    <vs-select-item value="create" text="<b>+ Criar novo tema</b>"/>
                    <vs-select-item :key="index" :value="item.model.id" :text="item.text" v-for="(item,index) in themesList" />
                  </vs-select>
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('class_content_topic_id')"
                    >{{ errors.first('class_content_topic_id') }}</span
                  >
                </div>

                <!-- AGENDAMENTO -->
                <div>
                  <label>{{ $t('agendar-para') }}</label>
                  <div class="flex">
                    <datetime
                        v-model="start_at.date"
                        @input="startChanged('date')"
                        input-class="vs-inputx vs-input--input normal"
                        :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                        type="date"
                        format="dd/MM/yyyy"
                        value-zone="America/Sao_Paulo"
                        class="flex-auto w-8/12 mr-1 tour-config-4"
                        :placeholder="$t('data')"
                        auto
                    >
                    </datetime>
                    <datetime
                        v-model="start_at.time"
                        @input="startChanged('time')"
                        input-class="vs-inputx vs-input--input normal"
                        :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                        :placeholder="$t('hora')"
                        type="time"
                        minute-step="5"
                        format="HH:mm"
                        value-zone="America/Sao_Paulo"
                        use24-hour
                        class="flex-auto w-4/12"
                    >
                    </datetime>
                  </div>
                </div>

                <!-- PONTUAÇÃO -->
                <div v-if="isType('A')">
                  <label>{{ $t('pontuacao') }}:</label>
                  <vs-input
                    v-model="score"
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    class="w-full"
                  />
                </div>

                <!-- DATA ENTREGA -->
                <div v-if="isType('A')">
                  <label>{{ $t('data-de-entrega') }}</label>
                  <div class="flex">
                    <datetime
                      v-model="due_at.date"
                      @input="dueChanged('date')"
                      input-class="vs-inputx vs-input--input normal"
                      :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                      type="date"
                      format="dd/MM/yyyy"
                      value-zone="America/Sao_Paulo"
                      class="flex-auto w-8/12 mr-1 tour-config-4"
                      :placeholder="$t('data-0')"
                      auto
                    >
                    </datetime>
                    <datetime
                      v-model="due_at.time"
                      @input="dueChanged('time')"
                      input-class="vs-inputx vs-input--input normal"
                      :phrases="{ok: 'Ok', cancel: 'Cancelar'}"
                      :placeholder="$t('hora-0')"
                      type="time"
                      minute-step="5"
                      format="HH:mm"
                      value-zone="America/Sao_Paulo"
                      use24-hour
                      class="flex-auto w-4/12"
                    >
                    </datetime>
                  </div>
                </div>

                <div class="flex w-full justify-end gap-2">
                  <vs-button
                    @click="create">
                    {{ $t('action.save') }}
                  </vs-button>
                  <vs-button
                    type="border"
                    to="/contents">
                    {{ $t('action.cancel') }}
                  </vs-button>
                </div>

              </div>
            </div>
          </div>

        </vx-card>

      </tab-content>

      <tab-content v-if="false" :title="$t('correcao')" class="mb-5" icon="feather icon-check">

        <span>...</span>

      </tab-content>

    </form-wizard>
    <vs-popup :title="$t('enviar-arquivo')" :active.sync="showUploadModal">
      <content-file-upload @input="fileUploaded" :uploadUrl="fileUploadUrl" />
    </vs-popup>
    <vs-popup :title="$t('endereco-web')" :active.sync="showUploadLink">
      <url-input v-if="showUploadLink" @input="uploadLink = $event"/>
      <div class="w-full flex flex-row justify-end items-center pt-2">
        <vs-button
          :disabled="isEmpty(uploadLink)"
          @click="uploadLinkMedia">
          {{ $t('action.save') }}
        </vs-button>
      </div>
    </vs-popup>

  </div>
</template>

<script>

import FormWizard from '@/components/form-wizard/FormWizard'
import TabContent from '@/components/form-wizard/TabContent'
import ComplexEditor from '../../questionnaires/elements/ComplexEditor'
import FeatherIcon from '@/components/FeatherIcon.vue'
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons'
import EduGooglePicker from '@/components/EduGooglePicker.vue'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

import ContentService from '@/services/api/ContentService'
import ContentFileUpload from '@/components/ContentFileUpload.vue'
import ContentMediaList from '../ContentMediaList.vue'
import UrlInput from '@/components/UrlInput.vue'
import ClassService from '@/services/api/ClassService'

export default {
  components: {
    ComplexEditor,
    FormWizard,
    TabContent,
    FeatherIcon,
    EduGooglePicker,
    Datetime,
    ContentFileUpload,
    ContentMediaList,
    UrlInput
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    selectThemeKey: 0,
    selectedClasses: [],
    classesList: [],
    creatingTheme: false,
    creatingThemeTitle: null,
    showUploadModal: false,
    showUploadLink: false,
    uploadLink: '',
    service: null,
    icons: {
      faGoogleDrive: faGoogleDrive,
    },
    theme: null,
    themes: [],
    title: null,
    type: 'M',
    editorData: '',
    start_at: {
      date: null,
      time: null,
    },
    due_at: {
      date: null,
      time: null,
    },
    score: 0,
    medias: [],
    debouncedGetThemes: null,
  }),
  watch: {
    selectedClasses(classes) {
      if (this._.isArray(classes) && classes.length > 0) {
        this.debouncedGetThemes()
      } else if (this._.isArray(classes) && classes.length === 0) {
        this.themes = []
        this.creatingTheme = false
        this.theme = null
        this.creatingThemeTitle = null
      }
    }
  },
  computed: {
    hasAnyClassSelected() {
      return (this.selectedClasses || []).length > 0
    },
    fileUploadUrl() {
      const base = `${process.env.VUE_APP_API_BASE_URL}/api/v1`
      return `${base}/contents/${this.id}/media/file`
    },
    themesList() {
      return this.themes.map((theme) => ({
        text: this._.get(theme, 'title'),
        model: theme
      }))
    }
  },
  methods: {
    isType(type) {
      return this.type === type
    },
    studentsQuantity(classItem) {
      let studentQuantity = 0
      if (!this.isEmpty(classItem)) {
        const classDisciplines = classItem.class_disciplines
        classDisciplines.forEach(classDiscipline => {
          studentQuantity += classDiscipline.class_students.length
        })
      }
      return studentQuantity
    },
    fillClasses() {
      if (this.$acl.hasPermission('classes.show')) {
        this.classService.teacherClasses(null).then((response) => {
          this.classesList = response
        })
      }
    },
    checkThemeCreation(value) {
      if (value === 'create') {
        this.creatingTheme = true
        this.$nextTick(() => {
          const input = this._.get(this, '$refs.theme-input')
          if (input && 'focusInput' in input) {
            input.focusInput()
          }
        })
      }
    },
    fillThemes() {
      const classes = this.selectedClasses
      this.service.getThemes(classes).then(
        response => {
          if (this._.isArray(response)) {
            this.themes = response
          }
          if (!this.creatingTheme && this.theme) {
            const themes = this._.get(this, 'themes', []) || []
            const themeIds = this._.map(themes, 'id')
            const themeId = this._.get(this.theme, 'id')
            if (themeId && !themeIds.includes(themeId)) {
              this.theme = null
            }
          }
          this.selectThemeKey++
        },
        error => {
        }
      )
    },
    uploadGDriveMedia(driveDocData) {
      if (!this.verifyIfCreated()) return
      this.service.uploadGDriveMedia(this.id, driveDocData).then(
        response => {
          this.$vs.loading.close()
          this.loadMedias(this.id)
        },
        error => {
          this.notifyError(this.$t('nao-foi-possivel-salvar-este-anexo'))
          this.$vs.loading.close()
        }
      )
    },
    uploadLinkMedia() {
      if (!this.verifyIfCreated()) return
      const link = this.uploadLink
      this.$vs.loading()
      this.service.uploadLinkMedia(this.id, link).then(
        response => {
          this.showUploadLink = false
          this.uploadLink = ''
          this.$vs.loading.close()
          this.loadMedias(this.id)
        },
        error => {
          this.notifyError(this.$t('nao-foi-possivel-salvar-este-anexo'))
          this.$vs.loading.close()
        }
      )
    },
    showUploadLinkModal() {
      if (!this.verifyIfCreated()) return
      this.showUploadLink = true
    },
    deleteMedia(media) {
      const id = this._.get(media, 'id')
      if (id) {
        this.$vs.loading()
        this.service.deleteMedia(id).then(
          response => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.loadMedias(this.id)
          },
          error => {
            this.$vs.loading.close()
          }
        )
      }
    },
    loadMedias(contentId) {
      this.service.getMedias(contentId).then(
        response => {
          if (this._.isArray(response) && response.length > 0) {
            this.medias = response
          } else {
            this.medias = []
          }
          console.warn('No media found for this Content.')
        },
        error => {
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-carregar-os-anexos'))
        }
      )
    },
    verifyIfCreated() {
      if (this.id) return true

      this.notifyWarning(this.$vs, this.$t('voce-deve-salvar-antes-de-anexar-arquivos'), 5000)
      return false
    },
    showUploadFile() {
      if (!this.verifyIfCreated()) return
      this.showUploadModal = true
    },
    fileUploaded(data) {
      /**
       * Attention: Data is a base64 of the file.
       * I dont think we need it.
       */
      this.showUploadModal = false
      this.loadMedias(this.id)
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          this.fillForm(response)
          const startAt = this._.get(response, 'release_at')
          const dueAt = this._.get(response, 'due_at')
          this.fillStartAt(startAt)
          this.fillDueAt(dueAt)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
      this.loadMedias(id)
    },
    readStartAt() {
      if (this.start_at.date && this.start_at.time) {
        const startAtDate = new Date(this.start_at.date)
        const startAtTime =  new Date(this.start_at.time)

        const startAt = (new Date())
        startAt.setUTCFullYear(
          startAtDate.getFullYear(),
          startAtDate.getMonth(),
          startAtDate.getDate()
        )

        startAt.setUTCHours(startAtTime.getHours())
        startAt.setUTCMinutes(startAtTime.getMinutes())
        startAt.setUTCSeconds(0)
        startAt.setUTCMilliseconds(0)

        return `${startAt.toISOString().slice(0, -1)}`
      } else { return null }
    },
    getDueAtTimestamp() {
      if (this.due_at.date && this.due_at.time) {
        const dueAtDate = new Date(this.due_at.date)
        const dueAtTime =  new Date(this.due_at.time)

        const dueAt = (new Date())
        dueAt.setUTCFullYear(
          dueAtDate.getFullYear(),
          dueAtDate.getMonth(),
          dueAtDate.getDate()
        )

        dueAt.setUTCHours(dueAtTime.getHours())
        dueAt.setUTCMinutes(dueAtTime.getMinutes())
        dueAt.setUTCSeconds(0)
        dueAt.setUTCMilliseconds(0)

        return `${dueAt.toISOString().slice(0, -1)}`
      } else { return null }
    },
    fillForm(model) {
      /**
       * This syntax is weird, but its also quite short, is it not?
       */
      ({
        title: this.title,
        instructions: this.editorData,
        type: this.type,
        class_content_topic_id: this.theme,
        selected_classes: this.selectedClasses,
        score: this.score,
      } = model)
    },
    readForm() {
      /**
       * I know this syntax is weird, but i'm trying something different
       * to see how it fares.
       */
      const {
        title,
        type,
        editorData: instructions,
        id,
        creatingThemeTitle: theme_title,
        theme: class_content_topic_id,
        selectedClasses: selected_classes
      } = this

      const release_at = this.readStartAt()
      const due_at = this.getDueAtTimestamp()

      const data = {
        title, type, instructions, id, release_at, selected_classes
      }

      if (this.isType('A')) {
        data.due_at = due_at
        data.score = this.score
      }

      if (!this.isEmpty(theme_title) && this.creatingTheme) {
        data.create_theme = theme_title
      } else {
        data.class_content_topic_id = class_content_topic_id
      }

      return data
    },
    fillStartAt(dateString) {
      if (!this.isEmpty(dateString)) {
        const date = new Date(dateString)
        const dateISO = date.toISOString()
        this.start_at.date = dateISO
        this.start_at.time = dateISO
      }
    },
    fillDueAt(dateString) {
      if (!this.isEmpty(dateString)) {
        const date = new Date(dateString)
        const dateISO = date.toISOString()
        this.due_at.date = dateISO
        this.due_at.time = dateISO
      }
    },
    create() {
      const model = this.readForm()

      this.$vs.loading()
      this.service.createOrUpdate(model).then(
        response => {
          this.$vs.loading.close()
          const id = this._.get(response, 'id')
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$router.push(`/contents/${id}/edit`)

          if (this.creatingTheme) {
            this.creatingThemeTitle = ''
            this.theme = this._.get(response, 'class_content_topic_id')
            this.creatingTheme = false
            this.debouncedGetThemes()
          }

        },
        error => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      )

    },
    // ------------------------------
    tabChanged(prevIndex, nextIndex) {

    },
    change(data) {
      const docs = this._.get(data, 'docs', []) || []
      docs.forEach((driveDoc) => {
        if (driveDoc) {
          this.uploadGDriveMedia(driveDoc)
        }
      })
    },
    loaded() {
      const picker = document.querySelector('.picker-dialog')
      picker.style.zIndex = '999999999999999999999'
    },
    dueChanged(field) {
      if (field === 'date' && this.isEmpty(this.due_at.time)) {
        this.due_at.time = (new Date()).toISOString()
      } else if (this.isEmpty(this.due_at.date)) {
        this.due_at.date = this.due_at.time
      }
    },
    startChanged(field) {
      if (field === 'date' && this.isEmpty(this.start_at.time)) {
        this.start_at.time = (new Date()).toISOString()
      } else if (this.isEmpty(this.start_at.date)) {
        this.start_at.date = this.start_at.time
      }
    },

  },
  created() {
    this.debouncedGetThemes = this._.debounce(this.fillThemes)
    this.fillStartAt(this.$moment())
  },
  mounted() {
    this.service = ContentService.build(this.$vs)
    this.classService = ClassService.build(this.$vs)
    // this.debouncedGetThemes()
    this.fillClasses()
    if (!this.isEmpty(this.id)) {
      this.getData(this.id)
    }
  }
}
</script>


<style lang="scss" scoped>
  .create-button {
    bottom: 0;
    position: absolute;
    right: 14px;
    margin-bottom: 10px;
  }
</style>
